<template>
    <div>
        <ViewTitle title="商品資料庫 | 商品資料總表" />
        <ProductSummaryFilter
            :isLoading="isLoadingTable"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
        />
        <ProductSummaryTable
            :isLoading="isLoadingTable"
            :filterLog="filterLog"
            :listData="listData"
        />
    </div>
</template>

<script>
// import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import ProductSummaryFilter from '@/containers/product/ProductSummaryFilter.vue'
import ProductSummaryTable from '@/containers/product/ProductSummaryTable.vue'
import { postProductSummaryAPI } from '@/assets/javascripts/api'

export default {
    name: 'ProductSummary',
    components: {
        ViewTitle,
        ProductSummaryFilter,
        ProductSummaryTable
    },
    methods: {
        showDataTable: async function () {
            this.$showLoading()
            this.isLoadingTable = true
            const p = this.filterPayload

            try {
                const res = await this.$getResponse(
                    p,
                    {
                        apiAlias: 'list',
                        apiName: '此報表'
                    },
                    postProductSummaryAPI
                )
                this.listData = res.data?.products || []
            } catch (e) {
                if (e?.message === 'noPermission') {
                    this.$showErrorMessage(
                        '此帳號尚無查看此報表的權限',
                        '請確認您的帳號權限，如有疑問請洽飛鴿'
                    )
                } else {
                    this.$showErrorMessage(
                        '查詢時出現錯誤，請稍後再試',
                        '若持續出現此問題，請聯繫資訊客服'
                    )
                }
                this.listData = []
            } finally {
                this.isLoadingTable = false
                this.$hideLoading()
            }
        }
    },
    data() {
        return {
            isLoadingTable: false,
            filterPayload: {
                supplierCode: '',
                productName: '',
                dateType: '',
                onSaleDateStart: '',
                onSaleDateEnd: '',
                offSaleDateStart: '',
                offSaleDateEnd: ''
            },
            filterLog: {},
            listData: []
        }
    }
}
</script>

<style lang="scss" scoped></style>
