<template>
    <Table
        ref="table"
        :columnConfig="tableColumnConfig"
        :rows="tableShowData"
        :toolbox="['columnSetting', 'excel']"
        :isFocusRow="true"
        :isLoading="isLoading"
        :sortDefault="{ sort: 'desc', sortBy: 'onSaleDate' }"
        :excelConfig="excelConfig"
        :isFixedHeader="true"
        :isPagination="true"
    />
</template>
<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import { productSummaryColumns } from '@/assets/javascripts/product/setting'
export default {
    name: 'ProductSummaryTable',
    components: { Table },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        transformData(rows) {
            const transPersistency = (val) => {
                if (val === null) return '-'
                return val ? '1' : '0'
            }
            return rows.map((row) => {
                row.productName = row.name || '-'
                row.isYearEnd = row.hasYeb ? '1' : '0'
                row.is13Persistency = transPersistency(row.has13Persistency)
                row.is25Persistency = transPersistency(row.has25Persistency)
                row.is37Persistency = transPersistency(row.has37Persistency)
                for (let i = 1; i <= this.maxPeriod; i++) {
                    row[`commissionRate${i}`] =
                        this.$roundX(+row.policyYear[i]?.rate, 2) || '-'
                }
                // excel setting
                row.offSaleDate = row.offSaleDate || '-'
                row.renewalAgeUpper = row.renewalAgeUpper || '-'
                row.invPremiumType = row.invPremiumType || '-'
                row.invFypLower = row.invFypLower || '-'
                row.invFypUpper = row.invFypUpper || '-'
                return row
            })
        }
    },
    computed: {
        tableColumnConfig() {
            const config = _.cloneDeep(productSummaryColumns)
            for (let i = 2; i <= this.maxPeriod; i++) {
                config[`commissionRate${i}`] = {
                    ...config.commissionRate1,
                    label: `續年佣金率%\n(${i})`
                }
            }
            return config
        },
        tableShowData() {
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        excelConfig() {
            return {
                fileName: '商品資料庫_商品資料總表',
                filters: this.filterLog
            }
        }
    },
    watch: {
        listData: {
            handler() {
                let period = this.maxPeriod
                this.listData.forEach((data) => {
                    const l = data.policyYearOptions.length
                    if (l > period) period = l
                })
                this.maxPeriod = period
            },
            deep: true
        }
    },
    data() {
        return {
            maxPeriod: 1
        }
    }
}
</script>
<style scoped lang="scss">
.table-wrapper {
    :deep(.table) {
        .mobile-header {
            flex-direction: column;
            p {
                display: flex;
                margin: 0;
                color: $secondary-grey;
                font-size: 12px;
                &:last-child {
                    span {
                        white-space: pre;
                    }
                }
            }
        }
    }
}
</style>
